import React, { useState } from "react";
import clsx from "clsx";
import "./Menu.css";
import PropTypes from 'prop-types';

import { translate } from 'react-switch-lang';
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from '@fortawesome/free-solid-svg-icons';

import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

import KeyboardArrowRightIcon from '@material-ui/icons/Close';

import Language from '../LanguageChange/LanguageChange';

const useStyles = makeStyles({
  list: {
    width: "auto",
  },
  fullList: {
    width: "auto",
  },
});

function MenuSideBar({ t, activeHome, activeTeam, activeBusiness }) {
  const classes = useStyles();

  const [activeHomeTab] = useState(activeHome);
  const [activeTeamTab] = useState(activeTeam);
  const [activeBusinessTab] = useState(activeBusiness);
  const [state, setState] = React.useState({
    top: false,
  });

  const anchor = "top";

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      }) + " menu"}
      role="presentation"
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className="menuSideBarHeader">
        <div style={{ display: "flex" }}>
          <img src={require("../../images/smallLogo.png")} alt="img" className="menuLogo" />
          <IconButton
            style={{ float: "right" }}
            onClick={toggleDrawer(anchor, false)}
            disableRipple={true}
          >
            <KeyboardArrowRightIcon className="rightArrow fooderlooGreen" />
          </IconButton>
        </div>
        <List className="list">
          <ListItem>
            {activeHomeTab ? (
              <div className="activeHome">
                <Link to="/home">
                  <Typography className=" fooderlooGreen MuiTypography-700 option">
                    {t('home')}
                  </Typography>
                </Link>
              </div>
            ) : (
                <div className="disable">
                  <Link to="/home">
                  <Typography className=" fooderlooGreen MuiTypography-700 option">
                      {t('home')}
                    </Typography>
                  </Link>
                </div>
              )}
          </ListItem>
          <ListItem>
            {activeTeamTab ? (
              <div className="activeTeam">
                <Link to="/team">
                  <Typography className=" fooderlooGreen MuiTypography-700 option">
                    {t('team')}
                  </Typography>
                </Link>
              </div>
            ) : (
                <div className="disable">
                  <Link to="/team">
                    <Typography className=" fooderlooGreen MuiTypography-700 option">
                      {t('team')}
                    </Typography>
                  </Link>
                </div>
              )}
          </ListItem>
          <ListItem>
            {activeBusinessTab ? (
              <div className="activeBusiness">
                <Link to="/business">
                  <Typography className=" fooderlooOrange MuiTypography-700 option">
                    {t('business')}
                  </Typography>
                </Link>
              </div>
            ) : (
                <div className="disable">
                  <Link to="/business">
                    <Typography className=" fooderlooOrange MuiTypography-700 option">
                      {t('business')}
                    </Typography>
                  </Link>
                </div>
              )}
          </ListItem>
          <ListItem>
            <Typography className="option"><Language /></Typography>
          </ListItem>
        </List>
      </div>
    </div>
  );

  return (
    <div className="menuSideBar">
      <IconButton
        edge="start"
        color="inherit"
        onClick={toggleDrawer(anchor, true)}
        disableRipple={true}
      >
        <FontAwesomeIcon icon={faBars} className="icon fooderlooOrange" />
      </IconButton>
      <SwipeableDrawer
        className="menuSideBarDrawer"
        anchor={anchor}
        open={state[anchor]}
        onClose={toggleDrawer(anchor, false)}
        onOpen={toggleDrawer(anchor, true)}
        style={{ width: "100%" }}
      >
        {list(anchor)}
      </SwipeableDrawer>
    </div>
  );
}

MenuSideBar.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate(MenuSideBar);
