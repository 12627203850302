import { Component } from "react";
import { connect } from "react-redux";
import { getDataLike } from "../Action";

export class FetchData extends Component {
  componentDidMount() {
    this.props.getDataLike();
  }

  render() {
    return null;
  }
}

export default connect(
  null,
  { getDataLike }
)(FetchData);