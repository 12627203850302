import React from 'react';
import './LanguageChange.css';

import { makeStyles } from '@material-ui/core/styles';
import { setLanguage, getLanguage } from 'react-switch-lang';

import Flag from 'react-world-flags'

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    paper: {
        marginRight: theme.spacing(2),
    },
}));

function Language() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleSetLanguage = (key) => () => {
        setLanguage(key);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <div className={classes.root + " tongue"}>
            <div>
                <Button
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    disableRipple="true"
                    className="button"
                >
                    {getLanguage() === 'en' ? (
                        <>
                            <Flag code="gb" className="flag" />
                            <Typography variant="h6" className={classes.title + " language fooderlooOrange MuiTypography-700"}>
                                EN
          </Typography>
                        </>
                    ) : (
                            <>
                                <Flag code="gr" className="flag" />
                                <Typography variant="h6" className={classes.title + " language fooderlooOrange MuiTypography-700"}>
                                    GR
          </Typography>
                            </>
                        )}
                </Button>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                        <button type="button" onClick={handleSetLanguage('en')} className="buttonlang">
                                            <MenuItem onClick={() => {
                                                handleClose('en');
                                            }}>
                                                <Flag code="gb" className="flag" />
                                                <Typography variant="h6" className={classes.title + " language fooderlooOrange MuiTypography-500"}>
                                                    EN
          </Typography>
                                            </MenuItem>
                                        </button>
                                        <Divider />
                                        <button type="button" onClick={handleSetLanguage('gr')} className="buttonlang">
                                            <MenuItem onClick={() => {
                                                handleClose('gr');
                                            }}>
                                                <Flag code="gr" className="flag" />
                                                <Typography variant="h6" className={classes.title + " language fooderlooOrange MuiTypography-500"}>
                                                    GR
          </Typography>
                                            </MenuItem>
                                        </button>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        </div>
    );
}

export default Language;