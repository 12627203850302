import React from 'react';
import './Value.css';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

export default function Value(props) {
    const [dense] = React.useState(false);
    const list1 = [props.value1, props.value2, props.value3];
    const list2 = [props.value4, props.value5, props.value6];
    return (
        <div className="value">
            <Typography variant="h3" className="fooderlooGreen MuiTypography-700">
                {props.header}
            </Typography>
            <Grid container xs={12} spacing={0}>
                    <Grid item xs={12} sm={6}>
                        <List dense={dense}>
                            {list1.map(value => {
                                return (
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar className="fooderlooGreenBack">
                                                <CheckIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={<Typography variant="h5" className="fooderlooGreen MuiTypography-700">{value}</Typography>}
                                        />
                                    </ListItem>
                                )
                            })}
                        </List>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <List dense={dense}>
                            {list2.map(value => {
                                return (
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar className="fooderlooOrangeBack">
                                                <CloseIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={<Typography variant="h5" className="fooderlooOrange MuiTypography-700">{value}</Typography>}
                                        />
                                    </ListItem>
                                )
                            })}
                        </List>
                    </Grid>
            </Grid>
        </div >
    );
}