import { takeEvery, call, put } from "redux-saga/effects";
import { DATA_GET_LIKE } from "./ActionTypes";
import { DATA_POST_LIKE } from "./ActionTypes";
import { DATA_LOADED_LIKE } from "./ActionTypes";
import { DATA_REQUESTED_LIKE } from "./ActionTypes";
import { API_ERRORED } from "./ActionTypes";

import axios from "axios";

export default function* watcherSaga() {
    yield takeEvery(DATA_REQUESTED_LIKE, workerSaga1);
    yield takeEvery(DATA_POST_LIKE, workerSaga2);
}

function* workerSaga1() {
    try {
        const payload = yield call(getDataLike);
        yield put({ type: DATA_LOADED_LIKE, payload });
    } catch (e) {
        yield put({ type: API_ERRORED, payload: e });
    }
}

function* workerSaga2(action) {
    try {
        const payload = yield call(postDataLike, action.payload.data);
        yield put({ type: DATA_GET_LIKE, payload });
    } catch (e) {
        yield put({ type: API_ERRORED, payload: e });
    }
}

function getDataLike() {
    return fetch("https://api.fooderloo.com/api/emailLikes")
        .then((response) => response.json()
        );
}

export const postDataLike = (query) => {
    return axios.post("https://api.fooderloo.com/api/emailImport", query)
}





