import { DATA_LOADED_LIKE } from "./ActionTypes";
import { DATA_GET_LIKE } from "./ActionTypes";

const initialState = {
    like: {},
};

function Reducer(state = initialState, action) {
    switch (action.type) {
        case DATA_LOADED_LIKE:
            return Object.assign({}, state, {
                like: action.payload,
        });
        case DATA_GET_LIKE:
            return Object.assign({}, state, {
                like: action.payload.data,
        });
        default:
            return state;
    }
};

export default Reducer;


