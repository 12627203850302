import React from 'react';
import PropTypes from 'prop-types';
import "./PopupEmail.css";

import { translate } from 'react-switch-lang';
import { Field, Control, Input, Button, Icon } from 'rbx';
import { connect } from "react-redux";
import { postDataLike } from "../../redux/Action";
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      isProvider: false,
      open: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.emailChange = this.emailChange.bind(this);
    this.checkChange = this.checkChange.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.postDataLike(this.state);
    this.resetForm();
    this.setState({ open: true });
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ open: false });
  };

  resetForm() {
    this.setState({
      email: '',
      check: false
    });
  }

  emailChange = (event) => {
    this.setState({ email: event.target.value })
  }

  checkChange = (event) => {
    this.setState({ isProvider: !this.state.isProvider })
  }

  render() {
    const { email } = this.state;
    const { t } = this.props;

    return (
      <div className="pop">
        <form onSubmit={this.handleSubmit} className="form">
          <Field>
            <Control iconLeft className="popInput">
              <Input
                name="email"
                type="email"
                placeholder={t('webform.emailpad')}
                value={email}
                onChange={this.emailChange}
                required
              />
            </Control>
            <Icon align="left">
                    <FontAwesomeIcon icon={faEnvelope} className="icon"/>
                  </Icon>
          </Field>
          <FormControlLabel
            className="checker"
            control={<Checkbox checked={this.state.isProvider} onChange={this.checkChange} name="checkedG" className="check fooderlooGreen" />}
            label={t('webform.checker')}
          />
          <Field kind="group">
            <Control>
              <Button className="popButton fooderlooGreen fooderlooGreenButton" color="dark">{t('webform.pop')}</Button>
            </Control>
          </Field>
        </form>
        <Snackbar open={this.state.open} autoHideDuration={3000} onClose={this.handleClose}>
          <Alert onClose={this.handleClose} severity="success">
            {t('thank')}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

ContactForm.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate(connect(null, { postDataLike })(ContactForm));
