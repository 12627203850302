import React from 'react';
import './Goal.css';

import Typography from '@material-ui/core/Typography';

export default function CircularStatic(props) {

    return (
        <div className="goal">
            <Typography variant="h3" className="fooderlooOrange MuiTypography-700">
                {props.head1}
            </Typography>
            <Typography variant="h6" className="fooderlooOrange line MuiTypography-700">
                {props.head2}
            </Typography>
            <div className="paragraph">
                <Typography className="MuiTypography-500">
                    {props.slogun}
                </Typography>
            </div>
            <div className="content" style={{ display: "flex" }}>
                {props.value === 0 ? (
                    <img src={require("../../images/pizza.png")} alt="pizza" className="pizza" />
                ) : (
                        <img src={require("../../images/cart.png")} alt="pizza" className="pizza" />
                    )}
                <div className="word">
                    <Typography variant={props.style} className={props.termvalue + " MuiTypography-700"} style={{ marginTop: "0.5em" }}>
                        {props.term}
                    </Typography>
                    <Typography variant={props.style2} className={props.term2value + " MuiTypography-700"}>
                        {props.term2}
                    </Typography>
                    <Typography variant={props.style3} className={props.term3value + " MuiTypography-700"}>
                        {props.term3}
                    </Typography>
                </div>
            </div>
        </div >
    );
}