import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-switch-lang";
import ScrollToTop from "../utilities/refresh";
import Appbar from "../components/Appbar/Appbar";
import Scroll from "../utilities/scroll";
import "./PrivacyPage.css"; // Import the CSS file

function PrivacyPage({ t }) {
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };
  ScrollToTop();

  return (
    <div className="privacyPage pp_body">
      <Scroll />
      <Appbar activeHome={false} activeTeam={false} activeBusiness={false} />
      <div>
        <title>Privacy Policy - Fooderloo</title>
        <h1>Welcome to the Privacy Policy of Fooderloo!</h1>
        <p>
          This Privacy Policy explains how Fooderloo collects, uses, and
          discloses your personal information in connection with the use of the
          Fooderloo application and any related services (collectively, the
          "Service").
        </p>

        <h2>Information Collected:</h2>
        <p>
          When you use the Service, Fooderloo may collect the following
          information:
        </p>
        <ul>
          <li>
            Personal information, such as your name, email address, and phone
            number, which you provide when creating an account
          </li>
          <li>
            Transaction information, such as the food products you purchase
            through the Service and the price you pay
          </li>
          <li>
            Device and usage information, such as your IP address, browser type,
            and the pages you visit on the Service
          </li>
          <li>Location information, if you choose to share it with us.</li>
        </ul>

        <h2>Use of Information:</h2>
        <p>Fooderloo uses the information collected to:</p>
        <ul>
          <li>Provide and improve the Service</li>
          <li>Process your transactions and payments</li>
          <li>Communicate with you about the Service and your account</li>
          <li>Detect and prevent fraud, abuse, and other illegal activities</li>
          <li>Comply with legal obligations.</li>
        </ul>

        <h2>Sharing of Information:</h2>
        <p>Fooderloo may share your information with:</p>
        <ul>
          <li>
            Business customers whose food products you purchase through the
            Service
          </li>
          <li>
            Service providers who assist us in providing the Service, such as
            payment processors and hosting providers
          </li>
          <li>
            Law enforcement or government agencies if required by law or to
            protect our legal rights
          </li>
          <li>
            Third-party advertising partners to display ads on the Service.
          </li>
        </ul>

        <h2>Security:</h2>
        <p>
          Fooderloo takes reasonable measures to protect your personal
          information from unauthorized access, use, or disclosure.
        </p>

        <h2>Retention:</h2>
        <p>
          Fooderloo will retain your personal information for as long as
          necessary to provide the Service and as required by law.
        </p>

        <h2>Your Choices:</h2>
        <p>
          You may update or delete your personal information through the Service
          at any time. You may also opt-out of receiving marketing
          communications from us by following the unsubscribe instructions in
          those communications.
        </p>

        <h2>Changes:</h2>
        <p>
          Fooderloo may update this Privacy Policy from time to time, and the
          updated version will be posted on the Service. Your continued use of
          the Service after any such changes constitute your acceptance of the
          updated Privacy Policy.
        </p>

        <h2>Contact:</h2>
        <p>
          If you have any questions or concerns about this Privacy Policy or the
          Service, please contact us at{" "}
          <a href="mailto:privacy@fooderloo.com">privacy@fooderloo.com</a>.
        </p>

        <p>Thank you for using Fooderloo!</p>
      </div>
    </div>
  );
}

PrivacyPage.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate(PrivacyPage);
