import { DATA_REQUESTED_LIKE } from "./ActionTypes";
import { DATA_POST_LIKE } from "./ActionTypes";

export function getDataLike() {
  return {
    type: DATA_REQUESTED_LIKE,
  }
};
export function postDataLike(data) {
  return {
    type: DATA_POST_LIKE,
    payload: { data }
  }
};

