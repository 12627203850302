import React from "react";
import PropTypes from "prop-types";

import { translate } from "react-switch-lang";
import ScrollToTop from "../utilities/refresh";

import Webform from "../components/Webform/Webform";
import Cover from "../components/Cover/Cover";
import Value from "../components/Value/Value";
import Functionality from "../components/Functionality/Functionality";
import Popup from "../components/Popup/Popup";
import Appbar from "../components/Appbar/Appbar";
import Scroll from "../utilities/scroll";

function ProviderPage({ t }) {
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };
  ScrollToTop();
  var object = {};
  object.value1 = t("providerPage.object.value1");
  object.value2 = t("providerPage.object.value2");
  object.slogun = t("providerPage.object.slogun");
  object.value = "h2";
  object.cover = 0;

  var object2 = {};
  object2.header = t("providerPage.object2.header");
  object2.value1 = t("providerPage.object2.value1");
  object2.value2 = t("providerPage.object2.value2");
  object2.value3 = t("providerPage.object2.value3");
  object2.value4 = t("providerPage.object2.value4");
  object2.value5 = t("providerPage.object2.value5");
  object2.value6 = t("providerPage.object2.value6");

  var object3 = {};
  object3.head = t("providerPage.object3.header");
  object3.step1 = t("providerPage.object3.step1");
  object3.value1 = t("providerPage.object3.value1");
  object3.step2 = t("providerPage.object3.step2");
  object3.value2 = t("providerPage.object3.value2");
  object3.step3 = t("providerPage.object3.step3");
  object3.value3 = t("providerPage.object3.value3");
  object3.slogun = t("providerPage.object3.slogun");

  var object4 = {};
  object4.love = t("popup.love");
  object4.text = t("popup.text");

  return (
    <div className="provider">
      <Scroll />
      <Appbar activeHome={false} activeTeam={false} activeBusiness={true} />
      <Cover {...object} />
      {/* <Popup {...object4} /> */}
      <Value {...object2} />
      <Functionality {...object3} />
      {/* TODO: Uncomment this contact us form once is fixed */}
      <Webform />
    </div>
  );
}

ProviderPage.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate(ProviderPage);
