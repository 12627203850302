import React from 'react';
import ReactDOM from 'react-dom';
 
import {
  setTranslations,
  setDefaultLanguage,
  setLanguageCookie, 
  getLanguage,
} from 'react-switch-lang';

import './font/SFCompactDisplay-Black.ttf';
import './font/SFCompactDisplay-Bold.ttf';
import './font/SFCompactDisplay-Heavy.ttf';
import './font/SFCompactDisplay-Light.ttf';
import './font/SFCompactDisplay-Medium.ttf';
import './font/SFCompactDisplay-Regular.ttf';
import './font/SFCompactDisplay-SemiBold.ttf';
import './font/SFCompactDisplay-Thin.ttf';
import './font/SFCompactDisplay-UltraLight.ttf';

import en from './data/english.json';
import gr from './data/greek.json';

import App from './App';
import './utilities/styling.css';
import './utilities/colors.css';
import './utilities/media.css';
import './utilities/font.css';

import FetchLike from './redux/auxiliary/FetchLike';

import { Provider } from "react-redux";
import store from "./redux/Store";

import * as serviceWorker from './serviceWorker';

setTranslations({ en, gr });
setDefaultLanguage('en');

setLanguageCookie(getLanguage());

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <FetchLike />
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
