import React from "react";
import "./Webform.css";
import PropTypes from "prop-types";
import nodemailer from "nodemailer";

import { translate } from "react-switch-lang";
import { Field, Control, Input, Icon, Button, Textarea } from "rbx";

import Typography from "@material-ui/core/Typography";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import SendIcon from "@material-ui/icons/Send";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";

require("dotenv").config();

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class ContactForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      subject: "",
      message: "",
      open: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.nameChange = this.nameChange.bind(this);
    this.emailChange = this.emailChange.bind(this);
    this.subjectChange = this.subjectChange.bind(this);
    this.messageChange = this.messageChange.bind(this);
  }
  handleSubmit(event) {
    event.preventDefault();
    const { name, email, subject, message } = this.state;

    // Create the request body
    const requestBody = {
      fullname: name,
      email: email,
      subject: subject,
      body: message,
    };

    fetch("https://api.fooderloo.com/api/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to submit contact form");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Contact form submitted successfully:", data);
        this.setState({ open: true });
        this.resetForm();
      })
      .catch((error) => {
        console.error("Error occurred while submitting contact form:", error);
        // Handle error here
      });
  }

  // handleSubmit(event) {
  //   event.preventDefault();
  //   const { name, email, subject, message } = this.state;
  //   // Create a Nodemailer transporter
  //   let transporter = nodemailer.createTransport({
  //     host: "smtp.hostinger.com",
  //     secure: true,
  //     port: 465,
  //     auth: {
  //       user: process.env.EMAIL,
  //       pass: process.env.EMAIL_PASSWORD,
  //     },
  //   });

  //   // Prepare the email content
  //   let mailOptions = {
  //     from: email,
  //     to: "contact@fooderloo.com", // replace with recipient's email
  //     subject: name + " " + subject,
  //     text: message,
  //   };

  //   // Send the email
  //   transporter.sendMail(mailOptions, function (error, info) {
  //     if (error) {
  //       console.log("Error occurred while sending email:", error);
  //       // Handle error here
  //     } else {
  //       console.log("Email sent:", info.response);
  //       this.setState({ open: true });
  //       this.resetForm();
  //     }
  //   });
  // }

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ open: false });
  };

  resetForm() {
    this.setState({
      name: "",
      email: "",
      subject: "",
      message: "",
    });
  }

  nameChange = (event) => {
    this.setState({ name: event.target.value });
  };

  emailChange = (event) => {
    this.setState({ email: event.target.value });
  };

  subjectChange = (event) => {
    this.setState({ subject: event.target.value });
  };

  messageChange = (event) => {
    this.setState({ message: event.target.value });
  };

  render() {
    const { name, email, subject, message } = this.state;
    const { t } = this.props;

    return (
      <div>
        <Typography variant="h3" className="fooderlooOrange MuiTypography-700">
          {t("webform.contact")}
        </Typography>
        <form onSubmit={this.handleSubmit} className="form">
          <div className="formContainer">
            <div className="content">
              <Field className="field">
                <Control iconLeft className="input">
                  <Input
                    name="name"
                    type="text"
                    placeholder={t("webform.namepad")}
                    value={name}
                    onChange={this.nameChange}
                    required
                  />
                  <Icon align="left">
                    <FontAwesomeIcon
                      icon={faUser}
                      style={{ width: "1.05em" }}
                      className="icon"
                    />
                  </Icon>
                </Control>
              </Field>
              <Field>
                <Control iconLeft className="input">
                  <Input
                    name="email"
                    type="email"
                    placeholder={t("webform.emailpad")}
                    value={email}
                    onChange={this.emailChange}
                    required
                  />
                  <Icon align="left">
                    <FontAwesomeIcon icon={faEnvelope} className="icon" />
                  </Icon>
                </Control>
              </Field>
              <Field>
                <Control iconLeft className="input">
                  <Input
                    name="subject"
                    type="text"
                    placeholder={t("webform.subjectpad")}
                    value={subject}
                    onChange={this.subjectChange}
                    required
                  />
                  <Icon align="left">
                    <FontAwesomeIcon
                      icon={faClipboard}
                      style={{ width: "1em" }}
                      className="icon"
                    />
                  </Icon>
                </Control>
              </Field>
            </div>
            <div className="main">
              <Field>
                <Control>
                  <Textarea
                    name="message"
                    placeholder={t("webform.messagepad")}
                    value={message}
                    onChange={this.messageChange}
                    className="message"
                    required
                  />
                </Control>
              </Field>
            </div>
          </div>
          <Field kind="group">
            <Control>
              <Button
                className="button fooderlooGreen fooderlooGreenButton"
                color="dark"
              >
                {t("webform.send")} <SendIcon className="sendIcon" />
              </Button>
            </Control>
          </Field>
        </form>
        <Snackbar
          open={this.state.open}
          autoHideDuration={3000}
          onClose={this.handleClose}
        >
          <Alert onClose={this.handleClose} severity="success">
            {t("success")}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

ContactForm.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate(ContactForm);
