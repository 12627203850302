import "./Team.css";
import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import { translate } from "react-switch-lang";

import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
  },
  gridList: {
    width: "auto",
    height: "auto",
  },
}));

function Team({ t, first_title, second_title }) {
  const classes = useStyles();

  return (
    <div className="team">
      <Typography
        variant="h3"
        className="fooderlooGreen MuiTypography-700 title"
      >
        {first_title}
      </Typography>
      <div className={classes.root + " content"}>
        <GridList
          container
          cellHeight={280}
          spacing={30}
          justify="space-between"
          alignItems="center"
          className={classes.gridList + " grid"}
        >
          <GridListTile className="tile" item xs={12} md={4}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={t("person2.link")}
            >
              <img
                src={require("../../images/Marios_Pafitis.png")}
                className="worker"
                alt="title"
              />
              <GridListTileBar
                title={t("person2.title")}
                subtitle={<span>{t("person2.author")}</span>}
              />
            </a>
          </GridListTile>
          <GridListTile className="tile">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={t("person4.link")}
            >
              <img
                src={require("../../images/Alex_Varianos.png")}
                className="worker"
                alt="title"
              />
              <GridListTileBar
                title={t("person4.title")}
                subtitle={<span>{t("person4.author")}</span>}
              />
            </a>
          </GridListTile>
          <GridListTile className="tile">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={t("person6.link")}
            >
              <img
                src={require("../../images/Rafael_Makrigiorgis.jpg")}
                className="worker"
                alt="title"
              />
              <GridListTileBar
                title={t("person6.title")}
                subtitle={<span>{t("person6.author")}</span>}
              />
            </a>
          </GridListTile>
        </GridList>
      </div>
      <Typography
        variant="h3"
        className="fooderlooGreen MuiTypography-700 title"
      >
        {second_title}
      </Typography>
      <div className={classes.root + " content"}>
        <GridList
          cellHeight={280}
          spacing={30}
          alignItems="center"
          className={classes.gridList + " grid"}
        >
          <GridListTile className="tile">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={t("person1.link")}
            >
              <img
                src={require("../../images/Ioannis_Evgeniou.png")}
                className="worker"
                alt="title"
              />
              <GridListTileBar
                title={t("person1.title")}
                subtitle={<span>{t("person1.author")}</span>}
              />
            </a>
          </GridListTile>
          <GridListTile className="tile">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={t("person3.link")}
            >
              <img
                src={require("../../images/Valentinos_Pariza.png")}
                className="worker"
                alt="title"
              />
              <GridListTileBar
                title={t("person3.title")}
                subtitle={<span>{t("person3.author")}</span>}
              />
            </a>
          </GridListTile>
          <GridListTile className="tile">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={t("person5.link")}
            >
              <img
                src={require("../../images/Marios_Aristodemou.png")}
                className="worker"
                alt="title"
              />
              <GridListTileBar
                title={t("person5.title")}
                subtitle={<span>{t("person5.author")}</span>}
              />
            </a>
          </GridListTile>
        </GridList>
      </div>
    </div>
  );
}

Team.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate(Team);
