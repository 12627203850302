import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-switch-lang";
import ScrollToTop from "../utilities/refresh";
import Appbar from "../components/Appbar/Appbar";
import Scroll from "../utilities/scroll";
import "./TermsAndConditions.css"; // Import the CSS file

function TermsAndConditionsCustomerPage({ t }) {
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };
  ScrollToTop();

  return (
    <div className="termsAndConditionsPage pp_body">
      <Scroll />
      <Appbar activeHome={false} activeTeam={false} activeBusiness={false} />
      <div>
        <title>Terms & Conditions - Fooderloo</title>
        <h1>Welcome to the Terms & Conditions (Customers) of Fooderloo!</h1>
        <p>
          These terms and services ("Agreement") are a legal agreement between
          you and Fooderloo, governing your use of the Fooderloo application
          ("App") and any related services (collectively, the "Service"). By
          using the Service, you agree to be bound by the terms of this
          Agreement. If you do not agree to these terms, you may not use the
          Service.
        </p>
        <p>
          "Business" refers to any restaurant, food service provider, retailer,
          or other commercial entity that uses the Service to offer goods or
          services for sale. A Business is responsible for all aspects of the
          transaction, including but not limited to the preparation, sale, and
          delivery of goods, as well as any customer service related to the
          transaction.
        </p>

        <h2>Use of the Service:</h2>
        <p>
          You may use the Service only for personal and non-commercial purposes.
          You may not use the Service for any illegal or unauthorized purpose.
          You agree to comply with all applicable laws, rules, and regulations
          in connection with your use of the Service.
        </p>

        <h2>Purchases:</h2>
        <p>
          You may purchase discounted food products from Business customers
          through the Service.
        </p>
        <p>
          You agree to pay the price displayed in the App, which includes any
          applicable taxes, and authorize Fooderloo to charge your credit card
          for the purchase price. All purchases are final, and no refunds will
          be issued.
        </p>

        <h2>Delivery:</h2>
        <p>
          In the event that delivery is selected, the responsibility of
          delivering the food products purchased through the Service falls on
          the Business, not the App. The Business will ensure that the goods are
          delivered to the designated location at the time specified in the App.
          The Service serves only as a platform for facilitating transactions
          and is not involved in the delivery process.
        </p>

        <h2>Food Safety:</h2>
        <p>
          Fooderloo is not responsible for the quality or safety of the food
          products sold through the Service. You assume all risks associated
          with consuming the food products, and Fooderloo recommends that you
          follow standard food safety practices when handling and preparing the
          food products.
        </p>

        <h2>Intellectual Property:</h2>
        <p>
          The Service and its content are protected by copyright, trademark, and
          other intellectual property laws. You may not reproduce, distribute,
          or otherwise use any content from the Service without the prior
          written consent of Fooderloo.
        </p>

        <h2>Indemnification:</h2>
        <p>
          You agree to indemnify and hold Fooderloo harmless from any claims,
          damages, liabilities, and expenses (including attorneys' fees) arising
          from your use of the Service or your breach of this Agreement.
        </p>

        <h2>Disclaimer of Warranties:</h2>
        <p>
          Fooderloo provides the Service on an "as is" and "as available" basis,
          without any warranties of any kind, either express or implied. To the
          fullest extent permissible under applicable law, Fooderloo disclaims
          all warranties, express or implied, including but not limited to,
          implied warranties of merchantability and fitness for a particular
          purpose. Fooderloo does not warrant that the food products sold
          through the Service are safe, will meet your personal requirements or
          expectations, or will be free from harmful materials.
        </p>
        <p>
          Fooderloo does not assume any liability or responsibility for any (i)
          errors, mistakes, or inaccuracies of the food products sold through
          the Service, (ii) any unauthorized access to or use of our secure
          servers and/or any personal information stored therein, (iii) any
          interruption or cessation of transmission to or from the Service, (iv)
          any harmful components that may be transmitted from the food products
          sold through the Service, or (v) any injury, illness, or damage of any
          nature whatsoever resulting from your access to and use of the Service
          or from the consumption of any food products sold through the Service.
        </p>

        <h2>User Account and Security:</h2>
        <p>
          You are responsible for maintaining the confidentiality of your
          account and password, and you agree to accept responsibility for all
          activities that occur under your account or password. You must notify
          Fooderloo immediately upon becoming aware of any breach of security or
          unauthorized use of your account.
        </p>

        <h2>Privacy Policy:</h2>
        <p>
          By using the Service, you agree to the terms of our Privacy Policy,
          which is hereby incorporated into these Terms and Conditions. Our
          Privacy Policy sets out how we collect, use, and protect your personal
          information.
        </p>

        <h2>Termination:</h2>
        <p>
          Fooderloo reserves the right, in its sole discretion, to terminate
          your access to the Service, without any prior notice, for any reason
          whatsoever, including without limitation if you breach these Terms and
          Conditions.
        </p>

        <h2>Dispute Resolution/Arbitration Clause:</h2>
        <p>
          Any dispute, claim, or controversy arising out of or relating to these
          Terms and Conditions, including the determination of the scope or
          applicability of this agreement to arbitrate, shall be determined by
          individual arbitration in the jurisdiction where Fooderloo operates,
          before one arbitrator. The arbitration shall be administered by a
          reputable arbitration institution under its rules.
        </p>

        <h2>Severability:</h2>
        <p>
          If any provision of these Terms and Conditions is deemed invalid or
          unenforceable for any reason, such provision shall be eliminated or
          limited to the minimum extent such that the remaining provisions of
          the Terms and Conditions will continue in full force and effect.
        </p>

        <h2>Entire Agreement:</h2>
        <p>
          These Terms and Conditions constitute the entire agreement between you
          and Fooderloo regarding the use of the Service, superseding any prior
          agreements between you and Fooderloo relating to your use of the
          Service.
        </p>

        <h2>Limitation of Liability:</h2>
        <p>
          To the extent permitted by law, Fooderloo shall not be liable for any
          indirect, incidental, special, or consequential damages arising out of
          or in connection with the Service, including without limitation,
          damages for lost profits, lost data, or business interruption.
        </p>

        <h2>Modification of Terms:</h2>
        <p>
          Fooderloo reserves the right to modify this Agreement at any time, and
          such modifications shall be effective immediately upon posting the
          modified Agreement on the App. Your continued use of the Service after
          any modification of this Agreement constitutes your acceptance of the
          modified Agreement.
        </p>

        <h2>Governing Law:</h2>
        <p>
          This Agreement shall be governed by and construed in accordance with
          the laws of the jurisdiction in which Fooderloo operates.
        </p>

        <p>
          If you have any questions or concerns about this Agreement or the
          Service, please contact us at{" "}
          <a href="mailto:support@fooderloo.com">support@fooderloo.com</a>.
        </p>

        <p>Thank you for using Fooderloo!</p>
      </div>
    </div>
  );
}

TermsAndConditionsCustomerPage.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate(TermsAndConditionsCustomerPage);
