import React from "react";
import "./Cover.css";

import Typography from "@material-ui/core/Typography";

export default function ButtonAppBar(props) {
  return (
    <div className="cover">
      {props.cover === 0 ? (
        <div className="coverContainer">
          <img
            src={require("../../images/customer_smaller.jpg")}
            className="cover"
            alt="cover2"
          />
        </div>
      ) : (
        <div className="coverContainer">
          <img
            src={require("../../images/provider_smaller.jpg")}
            className="cover"
            alt="cover2"
          />
        </div>
      )}
      <div className="title">
        <Typography
          variant={props.value}
          className={"MuiTypography-900 header " + props.color}
        >
          {props.value1}
        </Typography>
        <Typography
          variant={props.value}
          className={"MuiTypography-900 header " + props.color2}
        >
          {props.value2}
        </Typography>
      </div>
      <div className="buttons">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://play.google.com/store/apps/details?id=com.fooderloo"
        >
          <img
            src={require("../../images/playstore.png")}
            alt="play-store"
            className="store"
          />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://apps.apple.com/ca/app/fooderloo/id6462336579"
        >
          <img
            src={require("../../images/appstore.png")}
            alt="play-store"
            className="store"
          />
        </a>
      </div>
      <div className="slogun">
        <Typography variant="h6" className="MuiTypography-700">
          {props.slogun}
        </Typography>
      </div>
    </div>
  );
}
