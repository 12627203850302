import React from "react";
import "./Functionality.css";

import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 400,
    width: 300,
  },
}));

export default function SpacingGrid(props) {
  const classes = useStyles();
  var i = 0;
  return (
    <div className="functionality">
      <Typography
        variant="h3"
        className="fooderlooGreen title MuiTypography-700"
      >
        {props.head}
      </Typography>
      <Grid container justify="center" spacing={10}>
        {[props.step1, props.step2, props.step3].map((value) => {
          i++;
          return (
            <Grid key={value} item>
              {i === 1 ? (
                <Paper className={classes.paper}>
                  <img
                    className={classes.paper + " stepImage"}
                    alt="step"
                    src={require("../../images/Upload.png")}
                  />
                </Paper>
              ) : i === 2 ? (
                <Paper className={classes.paper}>
                  <img
                    className={classes.paper + " stepImage"}
                    alt="step"
                    src={require("../../images/Order.png")}
                  />
                </Paper>
              ) : (
                <Paper className={classes.paper}>
                  <img
                    className={classes.paper + " stepImage"}
                    alt="step"
                    src={require("../../images/QR.png")}
                  />
                </Paper>
              )}
              <Typography
                variant="h4"
                className="header main MuiTypography-700"
              >
                {value}
              </Typography>
              {i === 1 ? (
                <Typography variant="h6" className="header MuiTypography-300">
                  {props.value1}
                </Typography>
              ) : i === 2 ? (
                <Typography variant="h6" className="header MuiTypography-300">
                  {props.value2}
                </Typography>
              ) : (
                <Typography variant="h6" className="header MuiTypography-300">
                  {props.value3}
                </Typography>
              )}
            </Grid>
          );
        })}
      </Grid>
      <Typography variant="h6" className="slogun MuiTypography-300">
        {props.slogun}
      </Typography>
    </div>
  );
}
