import React from "react";
import "./Popup.css";

import { connect } from "react-redux";

import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";

import CloseIcon from "@material-ui/icons/Close";
import FavoriteIcon from "@material-ui/icons/Favorite";

import PopupEmail from "../PopupEmail/PopupEmail";

class Popup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchor: null,
      open: false,
      placement: "",
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick = (newPlacement) => (event) => {
    this.setState({
      anchor: event.currentTarget,
      open: !this.state.open,
      placement: newPlacement,
    });
  };

  componentDidMount() {
    // Dispatch the action that triggers the API call here.
    this.props.dispatch({ type: "DATA_REQUESTED_LIKE" }); // Replace with your action type
  }

  render() {
    return (
      <div className="popup">
        <Button className="button" onClick={this.handleClick("bottom-end")}>
          {this.props.love} <FavoriteIcon className="heart" />{" "}
          {this.props.like.counter}
        </Button>
        <Popper
          open={this.state.open}
          anchorEl={this.state.anchor}
          placement={this.state.placement}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <div className="notification MuiPaper-elevation1">
                <CloseIcon
                  onClick={this.handleClick("bottom-end")}
                  className="close fooderlooGreen"
                />
                <div className="text">{this.props.text}</div>
                <PopupEmail />
              </div>
            </Fade>
          )}
        </Popper>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    like: state.like,
  };
};

const PoP = connect(mapStateToProps)(Popup);

export default PoP;
