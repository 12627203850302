import "./Awards.css";
import React from 'react';

import Typography from '@material-ui/core/Typography';
import Carousel from "react-material-ui-carousel";

import image1 from "../../images/dc_1.jpg";
import image2 from "../../images/dc_2.jpg";
import image3 from "../../images/dc_3.JPG";
import image4 from "../../images/cl_1.jpg";
import image5 from "../../images/cl_2.jpg";
import image6 from "../../images/cl_3.jpg";
import image7 from "../../images/cl_4.jpg";
import image8 from "../../images/cl_5.jpg";
import image9 from "../../images/cl_6.jpg";
import image10 from"../../images/bs_1.jpg";
import image11 from"../../images/bs_2.jpg";
import image12 from"../../images/bs_3.jpg";
import image13 from"../../images/ggg_1.jpg";

export default function Awards(props) {
    var url = "https://misolutionframework.net/Innovations/name/asc?page=38";
    var url2 = "https://www.youtube.com/watch?v=hzMW5FyyD84&feature=youtu.be&t=1291";
    var url3 = "https://climatelaunchpad.org/finalists/fooderloo-ltd/";
    var url4 = "https://www.facebook.com/bridgesofsolidarityhackathon/";

    return (
        <div className="awards">
            <div className="tournament">
                <div className="logo">
                {props.check === 3 ? (
                        <a target="_blank" rel="noopener noreferrer" href={url4}>
                            <Typography variant="h5" className="fooderlooOrange MuiTypography-700 comptitle text">
                                {props.value1}
                            </Typography>
                        </a>
                    ) : props.check === 4 ? (
                        <a target="_blank" rel="noopener noreferrer" href={url}>
                            <Typography variant="h5" className="fooderlooOrange MuiTypography-700 comptitle text">
                                {props.value1}
                            </Typography>
                        </a>
                    ) : (
                                <Typography variant="h5" className="fooderlooOrange MuiTypography-700  comptitle text">
                                    {props.value1}
                                </Typography>
                            )}
                    {props.check === 1 ? (
                        <img src={require('../../images/Digital_Championship.png')} className="tournament dc img" alt="tournament" />
                    ) : props.check === 2 ? (
                        <img src={require('../../images/climate_launchpad.png')} className="cl img" alt="tournament" />
                    ) : props.check === 3 ? (
                        <img src={require('../../images/bs.png')} className="bs img" alt="tournament" />
                    ) : props.check === 4 ? (
                        <img src={require('../../images/mi.png')} className="mi img" alt="tournament" />
                    ) : props.check === 6 ? (
                        <img src={require('../../images/ggg.png')} className="ggg img" alt="tournament" />
                    ) : (
                                        <img src={require('../../images/wsa.png')} className="wsa img" alt="tournament" />
                                    )}                   
                </div>
                {props.check === 1 ? (
                    <Carousel navButtonsAlwaysVisible="true" animation="slide" className="carousel" interval={7000}>
                        <img
                            className="coverPhoto"
                            src={image1}
                            alt="tournament"
                        />
                        <img
                            className="coverPhoto"
                            src={image2}
                            alt="tournament"
                        />
                        <img
                            className="coverPhoto"
                            src={image3}
                            alt="tournament"
                        />
                    </Carousel>
                ) : props.check === 2 ? (
                    <Carousel navButtonsAlwaysVisible="true" animation="slide" className="carousel" interval={7000}>
                        <img
                            className="coverPhoto"
                            src={image4}
                            onLoad={() => console.log("Loading")}
                            alt="tournament"
                        />
                        <img
                            className="coverPhoto"
                            src={image5}
                            alt="tournament"
                        />
                        <img
                            className="coverPhoto"
                            src={image6}
                            alt="tournament"
                        />
                        <img
                            className="coverPhoto"
                            src={image7}
                            alt="tournament"
                        />
                        <img
                            className="coverPhoto"
                            src={image8}
                            alt="tournament"
                        />
                        <img
                            className="coverPhoto"
                            src={image9}
                            alt="tournament"
                        />
                    </Carousel>
                ) : props.check === 3 ? (
                    <Carousel navButtonsAlwaysVisible="true" animation="slide" className="carousel" interval={7000}>
                        <img
                            className="coverPhoto"
                            src={image10}
                            alt="tournament"
                        />
                        <img
                            className="coverPhoto"
                            src={image11}
                            alt="tournament"
                        />
                        <img
                            className="coverPhoto"
                            src={image12}
                            alt="tournament"
                        />
                    </Carousel>
                ) : props.check === 6 ? (
                    <Carousel navButtonsAlwaysVisible="true" animation="slide" className="carousel" interval={7000}>
                        <img
                            className="coverPhoto"
                            src={image13}
                            alt="tournament"
                        />
                    </Carousel>
                ) : (
                                <></>
                            )}
                {props.check === 2 ? (
                    <a target="_blank" rel="noopener noreferrer" href={url3}>
                        <Typography variant="h4" className="fooderlooSilver MuiTypography-700 text">
                            {props.value2}
                        </Typography>
                    </a>
                ) : props.check === 3 ? (
                    <a target="_blank" rel="noopener noreferrer" href={url4}>
                        <Typography variant="h4" className="fooderlooSilver MuiTypography-700 text">
                            {props.value2}
                        </Typography>
                    </a>
                ) : props.check === 4 ? (
                    <a target="_blank" rel="noopener noreferrer" href={url}>
                        <Typography variant="h4" className="fooderlooSilver MuiTypography-700 text">
                            {props.value2}
                        </Typography>
                    </a>
                ) : props.check === 6 ? (
                        <Typography variant="h4" className="fooderlooSilver MuiTypography-700 text">
                            {props.value2}
                        </Typography>
                ) : (
                                <Typography variant="h4" className="fooderlooSilver MuiTypography-700 text">
                                    {props.value2}
                                </Typography>
                            )}
                {props.check === 4 ? (
                    <a target="_blank" rel="noopener noreferrer" href={url2}>
                        <Typography variant="h4" className="fooderlooSilver MuiTypography-700 text">
                            {props.value3}
                        </Typography>
                    </a>
                ) : (
                        <Typography variant="h4" className="fooderlooSilver MuiTypography-700 text final">
                            {props.value3}
                        </Typography>
                    )}
            </div>
        </div>
    );
}

