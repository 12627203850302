import React from "react";
import ReactPlayer from "react-player";
import PropTypes from "prop-types";

import { translate } from "react-switch-lang";

import ScrollToTop from "../utilities/refresh";

import Webform from "../components/Webform/Webform";
import Cover from "../components/Cover/Cover";
import Goal from "../components/Goal/Goal";
import Popup from "../components/Popup/Popup";
import Appbar from "../components/Appbar/Appbar";
import Scroll from "../utilities/scroll";

function HomePage({ t }) {
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };
  ScrollToTop();
  var object = {};
  object.value1 = t("homePage.object.value1");
  object.value2 = t("homePage.object.value2");
  object.value = "h1";
  object.cover = 1;

  var object2 = {};
  object2.head1 = t("homePage.object2.head1");
  object2.head2 = t("homePage.object2.head2");
  object2.slogun = t("homePage.object2.slogun");
  object2.term = t("homePage.object2.term");
  object2.term2 = t("homePage.object2.term2");
  object2.term3 = t("homePage.object2.term3");
  object2.termvalue = "fooderlooGreen";
  object2.term2value = "fooderlooGreen";
  object2.term3value = "fooderlooGreen";
  object2.style = "h5";
  object2.style2 = "h5";
  object2.style3 = "h3";
  object2.value = 0;

  var object3 = {};
  object3.head1 = t("homePage.object3.head1");
  object3.head2 = t("homePage.object3.head2");
  object3.slogun = t("homePage.object3.slogun");
  object3.term = t("homePage.object3.term");
  object3.term2 = t("homePage.object3.term2");
  object3.term3 = t("homePage.object3.term3");
  object3.termvalue = "fooderlooGreen";
  object3.term2value = "fooderlooGreen";
  object3.term3value = "fooderlooGreen";
  object3.style = "h3";
  object3.style2 = "h5";
  object3.style3 = "h5";
  object3.value = 1;

  var object4 = {};
  object4.love = t("popup.love");
  object4.text = t("popup.text");

  var check = 0;
  if (object.value1 === "FIND FOOD") check = 1;
  else {
    check = 0;
  }
  return (
    <div className="home">
      <Scroll />
      <Appbar activeHome={true} activeTeam={false} activeBusiness={false} />
      <Cover {...object} />
      {/* <Popup {...object4} /> */}
      <Goal {...object2} />
      <Goal {...object3} />
      <ReactPlayer
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "4em",
          marginBottom: "6em",
        }}
        url="https://youtu.be/fulfcT9Fs1I"
        playing={true}
        controls={false}
        light={true}
        className="player"
      />
      {/* {check === 1 ? (
        <ReactPlayer
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "4em",
            marginBottom: "6em",
          }}
          url="https://www.youtube.com/watch?v=ahyz2UeKchE"
          playing={true}
          controls={false}
          light={true}
          className="player"
        />
      ) : (
        <ReactPlayer
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "4em",
            marginBottom: "6em",
          }}
          url="https://www.youtube.com/watch?v=1EIxEhO15bk"
          playing={true}
          controls={false}
          light={true}
          className="player"
        />
      )} */}
      {/* TODO: Uncomment this contact us form once is fixed */}
      <Webform />
    </div>
  );
}

HomePage.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate(HomePage);
