import { createStore, applyMiddleware, compose } from "redux";
import Reducer from "./Reducer";
import createSagaMiddleware from "redux-saga";
import apiSaga from "./Saga";

const initialiseSagaMiddleware = createSagaMiddleware();

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  Reducer,
  storeEnhancers(
    applyMiddleware(initialiseSagaMiddleware)
  )
);

initialiseSagaMiddleware.run(apiSaga);

export default store;