import React from "react";

import { BrowserRouter, Route, Redirect } from "react-router-dom";
import { translate } from "react-switch-lang";

import PropTypes from "prop-types";
import CookieConsent from "react-cookie-consent";

import Footer from "./components/Footer/Footer";
import HomePage from "./pages/HomePage";
import ProviderPage from "./pages/ProviderPage";
import TeamPage from "./pages/TeamPage";
import PrivacyPage from "./pages/PrivacyPage";
import TermsAndConditionsProviderPage from "./pages/TermsAndConditionsProviderPage";
import TermsAndConditionsCustomerPage from "./pages/TermsAndConditionsCustomerPage";

function App({ t }) {
  return (
    <div className="content">
      <BrowserRouter>
        <Route path="/home" exact component={HomePage} />
        <Route path="/team" exact component={TeamPage} />
        <Route path="/business" exact component={ProviderPage} />
        <Route path="/privacy" exact component={PrivacyPage} />
        <Route
          path="/terms-customer"
          exact
          component={TermsAndConditionsCustomerPage}
        />
        <Route
          path="/terms-provider"
          exact
          component={TermsAndConditionsProviderPage}
        />
        <Footer />
        <CookieConsent
          location="bottom"
          buttonText={t("cookies.okay")}
          cookieName="myAwesomeCookieName2"
          style={{
            background: "#2ba577",
            color: "white",
            fontFamily: "SFCompactDisplay-Heavy",
          }}
          expires={150}
        >
          {t("cookies.info")}.{" "}
        </CookieConsent>
        <Redirect to="/home" />
      </BrowserRouter>
    </div>
  );
}

App.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate(App);
