import React, { useState } from "react";
import "./Appbar.css";
import PropTypes from "prop-types";

import { translate } from "react-switch-lang";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Headroom from "react-headroom";
import LanguageChange from "../LanguageChange/LanguageChange";
import Menu from "../Menu/Menu";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

function ButtonAppBar({ t, activeHome, activeTeam, activeBusiness }) {
  const classes = useStyles();

  const [activeHomeTab] = useState(activeHome);
  const [activeTeamTab] = useState(activeTeam);
  const [activeBusinessTab] = useState(activeBusiness);

  return (
    <Headroom>
      <div className={classes.root + " appbar appfixed"}>
        <AppBar position="static" className="app">
          <Toolbar className="toolbar">
            <img
              src={require("../../images/fooderloo_logo_horizontal.png")}
              alt="img"
              className="logo"
            />
            <div className="container">
              <div className="main green">
                {activeHomeTab ? (
                  <div className="activeHome">
                    <Link to="/home">
                      <Typography
                        variant="h5"
                        className={
                          classes.title + " fooderlooGreen MuiTypography-700"
                        }
                      >
                        {t("home")}
                      </Typography>
                    </Link>
                  </div>
                ) : (
                  <div className="disable">
                    <Link to="/home">
                      <Typography
                        variant="h5"
                        className={
                          classes.title + " fooderlooGreen MuiTypography-700"
                        }
                      >
                        {t("home")}
                      </Typography>
                    </Link>
                  </div>
                )}
              </div>
              <div className="main green">
                {activeTeamTab ? (
                  <div className="activeTeam">
                    <Link to="/team">
                      <Typography
                        variant="h5"
                        className={
                          classes.title + " fooderlooGreen MuiTypography-700"
                        }
                      >
                        {t("team")}
                      </Typography>
                    </Link>
                  </div>
                ) : (
                  <div className="disable">
                    <Link to="/team">
                      <Typography
                        variant="h5"
                        className={
                          classes.title + " fooderlooGreen MuiTypography-700"
                        }
                      >
                        {t("team")}
                      </Typography>
                    </Link>
                  </div>
                )}
              </div>
              <div className="main orange">
                {activeBusinessTab ? (
                  <div className="activeBusiness">
                    <Link to="/business">
                      <Typography
                        variant="h5"
                        className={
                          classes.title + " fooderlooOrange MuiTypography-700"
                        }
                      >
                        {t("business")}
                      </Typography>
                    </Link>
                  </div>
                ) : (
                  <div className="disable">
                    <Link to="/business">
                      <Typography
                        variant="h5"
                        className={
                          classes.title + " fooderlooOrange MuiTypography-700"
                        }
                      >
                        {t("business")}
                      </Typography>
                    </Link>
                  </div>
                )}
              </div>
              <LanguageChange className="lang" />
            </div>
          </Toolbar>
        </AppBar>
        <div className="drawer" style={{ display: "flex" }}>
          <img
            src={require("../../images/smallLogo.png")}
            alt="img"
            className="logoSmall"
          />
          {activeHome ? (
            <Menu activeHome={true} activeTeam={false} activeBusiness={false} />
          ) : activeTeam ? (
            <Menu activeHome={false} activeTeam={true} activeBusiness={false} />
          ) : (
            <Menu activeHome={false} activeTeam={false} activeBusiness={true} />
          )}
        </div>
      </div>
    </Headroom>
  );
}

ButtonAppBar.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate(ButtonAppBar);
