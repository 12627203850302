import React from "react";
import PropTypes from "prop-types";

import { translate } from "react-switch-lang";

import ScrollToTop from "../utilities/refresh";

import Typography from "@material-ui/core/Typography";
import Team from "../components/Team/Team.js";
import Awards from "../components/Awards/Awards.js";
import Appbar from "../components/Appbar/Appbar";
import Scroll from "../utilities/scroll";

function TeamPage({ t }) {
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };
  ScrollToTop();

  var title1 = t("teamPage.title1");
  var supportteam_title1 = t("teamPage.supportteam_title1");
  var title2 = t("teamPage.title2");

  var object = {};
  object.value1 = t("teamPage.object.value1");
  object.value2 = t("teamPage.object.value2");
  object.value3 = t("teamPage.object.value3");
  object.check = 1;

  var object2 = {};
  object2.value1 = t("teamPage.object2.value1");
  object2.value2 = t("teamPage.object2.value2");
  object2.check = 2;

  var object3 = {};
  object3.value1 = t("teamPage.object3.value1");
  object3.value2 = t("teamPage.object3.value2");
  object3.value3 = t("teamPage.object3.value3");
  object3.check = 3;

  var object4 = {};
  object4.value1 = t("teamPage.object4.value1");
  object4.value2 = t("teamPage.object4.value2");
  object4.value3 = t("teamPage.object4.value3");
  object4.check = 4;

  var object5 = {};
  object5.value1 = t("teamPage.object5.value1");
  object5.value2 = t("teamPage.object5.value2");

  var object6 = {};
  object6.value1 = t("teamPage.object6.value1");
  object6.value2 = t("teamPage.object6.value2");
  object6.check = 6;

  return (
    <div className="teamPage">
      <Scroll />
      <Appbar activeHome={false} activeTeam={true} activeBusiness={false} />
      <Team first_title={title1} second_title={supportteam_title1} />
      <Typography
        variant="h3"
        className="fooderlooGreen MuiTypography-700 title awardTitle"
      >
        {title2}
      </Typography>
      <Awards {...object6} />
      <Awards {...object5} />
      <Awards {...object} />
      <Awards {...object2} />
      <Awards {...object3} />
      <Awards {...object4} />
    </div>
  );
}

TeamPage.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate(TeamPage);
