import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-switch-lang";
import ScrollToTop from "../utilities/refresh";
import Appbar from "../components/Appbar/Appbar";
import Scroll from "../utilities/scroll";
import "./TermsAndConditions.css"; // Import the CSS file

function TermsAndConditionsProviderPage({ t }) {
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };
  ScrollToTop();

  return (
    <div className="termsAndConditionsPage pp_body">
      <Scroll />
      <Appbar activeHome={false} activeTeam={false} activeBusiness={false} />
      <div>
        <title>Terms & Conditions - Fooderloo</title>
        <h1>Welcome to the Terms & Conditions (Providers) of Fooderloo!</h1>
        <p>
          We are a food service application that aims to reduce food waste by
          offering discounted prices on food products that would otherwise be
          thrown away. As a business customer of Fooderloo, you have the
          opportunity to list your surplus food products on our platform for our
          customers to purchase at a discounted price.
        </p>
        <p>
          By using Fooderloo, you agree to the following terms and conditions:
        </p>

        <h2>Commission Fee:</h2>
        <p>
          Fooderloo operates on a transparent fee structure. We charge our
          providers based on the transactions facilitated through our platform.
          There are no hidden fees or additional charges beyond the
          transactional commission. Our aim is to maintain clarity and trust
          with our providers, ensuring that they are only charged for the value
          they derive from our services.
        </p>
        <ul>
          <li>
            JCC Commission: For every transaction facilitated through the
            Fooderloo platform, a commission fee attributable to JCC will be
            automatically deducted.
          </li>
          <li>
            Fooderloo's Introductory Offer: As part of our introductory offer,
            for the initial three months of service, Fooderloo will waive its
            commission fee. Please note that during this period, only the JCC
            commission will be applicable.
          </li>
          <li>
            Standard Commission: Post the introductory period, in addition to
            the JCC commission, Fooderloo will charge a standard commission fee
            of 10% on the total sale price of each transaction. This commission
            will be automatically deducted from the total transaction amount.
          </li>
          <li>
            Fee Adjustments: The commission rates, including Fooderloo's fee
            percentage, are subject to change based on Fooderloo's performance
            metrics during its service. Any changes to these rates will be
            communicated to our partners in advance.
          </li>
        </ul>

        <h2>Payment:</h2>
        <p>
          The total payment to business customers will be settled on the last
          day of each month. Payment will be made via bank transfer or as
          specified by the business customer.
        </p>

        <h2>Listing Requirements:</h2>
        <p>
          All food products listed on Fooderloo must be surplus items that would
          otherwise be thrown away. You agree to offer these products at a
          discounted price and understand that the final price is at the
          discretion of Fooderloo. Business customers are responsible for
          accurately describing their products and ensuring that they are safe
          for consumption. Fooderloo reserves the right to remove any products
          that do not meet these requirements.
        </p>

        <h2>Ordering and Delivery:</h2>
        <p>
          Business customers must provide a takeaway option for all orders.
          Customers are responsible for picking up their orders at the specified
          location. Delivery option will be available in the future.
        </p>

        <h2>User Accounts:</h2>
        <p>
          Business customers are responsible for maintaining the confidentiality
          of their account login information and for any activity that occurs
          under their account.
        </p>

        <h2>Disclaimer of Warranties:</h2>
        <p>
          Fooderloo is provided on an "as is" and "as available" basis. We make
          no representations or warranties of any kind, express or implied, as
          to the operation of the Fooderloo platform or the information,
          content, materials, or products included on the platform.
        </p>

        <h2>Privacy Policy:</h2>
        <p>
          By using the Service, you agree to the terms of our Privacy Policy,
          which is hereby incorporated into these Terms and Conditions. Our
          Privacy Policy sets out how we collect, use, and protect your personal
          information.
        </p>

        <h2>Termination:</h2>
        <p>
          Fooderloo reserves the right, in its sole discretion, to terminate
          your access to the Service, without any prior notice, for any reason
          whatsoever, including without limitation if you breach these Terms and
          Conditions.
        </p>

        <h2>Dispute Resolution/Arbitration Clause:</h2>
        <p>
          Any dispute, claim, or controversy arising out of or relating to these
          Terms and Conditions, including the determination of the scope or
          applicability of this agreement to arbitrate, shall be determined by
          individual arbitration in the jurisdiction where Fooderloo operates,
          before one arbitrator. The arbitration shall be administered by a
          reputable arbitration institution under its rules.
        </p>

        <h2>Severability:</h2>
        <p>
          If any provision of these Terms and Conditions is deemed invalid or
          unenforceable for any reason, such provision shall be eliminated or
          limited to the minimum extent such that the remaining provisions of
          the Terms and Conditions will continue in full force and effect.
        </p>

        <h2>Entire Agreement:</h2>
        <p>
          These Terms and Conditions constitute the entire agreement between you
          and Fooderloo regarding the use of the Service, superseding any prior
          agreements between you and Fooderloo relating to your use of the
          Service.
        </p>

        <h2>Limitation of Liability:</h2>
        <p>
          In no event shall we be liable for any damages of any kind arising
          from the use of Fooderloo, including but not limited to direct,
          indirect, incidental, punitive, and consequential damages.
        </p>
        <p>
          In no event shall we (Fooderloo) be liable for any food allergies,
          food poisoning or damaged food upon takeaway or delivery.
        </p>

        <h2>Governing Law:</h2>
        <p>
          These terms and conditions and your use of Fooderloo shall be governed
          by and construed in accordance with the laws of the state in which the
          business customer is located.
        </p>

        <h2>Agreement:</h2>
        <p>
          By using Fooderloo, you agree to be bound by these terms and
          conditions. If you do not agree to these terms and conditions, please
          do not use the Fooderloo platform.
        </p>
        <p>
          We reserve the right to modify these terms and conditions at any time
          without prior notice. It is your responsibility to review these terms
          and conditions periodically for any updates or changes. Your continued
          use of Fooderloo following the posting of any changes to these terms
          and conditions constitutes your acceptance of those changes.
        </p>

        <p>
          If you have any questions or concerns about this Agreement or the
          Service, please contact us at{" "}
          <a href="mailto:support@fooderloo.com">support@fooderloo.com</a>.
        </p>

        <p>Thank you for using Fooderloo!</p>
      </div>
    </div>
  );
}

TermsAndConditionsProviderPage.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate(TermsAndConditionsProviderPage);
