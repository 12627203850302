import React from "react";
import "./Footer.css";

import { makeStyles } from "@material-ui/core/styles";
import { SocialIcon } from "react-social-icons";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";

import CopyrightIcon from "@material-ui/icons/Copyright";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function ButtonAppBar() {
  const classes = useStyles();
  return (
    <div className={classes.root + " footer appbar"}>
      <AppBar position="static">
        <Toolbar>
          <Grid container xs={12} spacing={0}>
            <Grid item xs={12} sm={12} md={8} lg={8} className="icons">
              <SocialIcon
                className="media"
                target="_blank"
                url="http://facebook.com/fooderloo"
              />
              <SocialIcon
                className="media"
                target="_blank"
                url="http://twitter.com/fooderloo"
              />
              <SocialIcon
                className="media"
                target="_blank"
                url="http://instagram.com/fooderloo"
              />
              <SocialIcon
                className="media"
                target="_blank"
                url="http://linkedin.com/company/68187417"
              />
              <SocialIcon
                className="media"
                target="_blank"
                url="http://youtube.com/channel/UCTrbIDWF5mjIEqBGrRLHnxg"
              />
            </Grid>
            <Grid xs={12} sm={12} md={4} lg={4}>
              <div className="buttons">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://play.google.com/store/apps/details?id=com.fooderloo"
                >
                  <img
                    src={require("../../images/playstore.png")}
                    alt="play-store"
                    className="store"
                  />
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://apps.apple.com/ca/app/fooderloo/id6462336579"
                >
                  <img
                    src={require("../../images/appstore.png")}
                    alt="ios-store"
                    className="store"
                  />
                </a>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              lg={12}
              container
              direction="column"
              alignItems="flex-start"
              className="text"
              spacing={1}
            >
              <Grid item>
                <Link to="/privacy" className="large-font footer-link">
                  Privacy & Policy
                </Link>
              </Grid>
              <Grid item>
                <Link to="/terms-customer" className="large-font footer-link">
                  Terms & Conditions (Customer)
                </Link>
              </Grid>
              <Grid item>
                <Link to="/terms-provider" className="large-font footer-link">
                  Terms & Conditions (Provider)
                </Link>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              lg={12}
              container
              direction="column"
              alignItems="center"
              className="text"
            >
              <Grid item xs={12} container justifyContent="center">
                <Typography variant="h7" className={classes.title}>
                  Copyright <CopyrightIcon className="copyright" /> 2021
                  Fooderloo LTD All Rights Reserved
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
}
